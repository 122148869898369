<template>
  <div class="container mescroll-touch">
    <p class="title">{{detail.Title}}</p>
    <video ref="video" class="video" :src="detail.Video" controls></video>
    <div class="flexBox">
      <div class="likeBtn" :class="{'activeLikeBtn': detail.IsLike}" @click="likes">
        <img :src="detail.IsLike ? require('@/assets/images/likes_red@2x.png') : require('@/assets/images/likes_ash@2x.png')" />
        <span>点赞</span>
      </div>
    </div>
    <p class="h2">评论</p>
    <div class="inputBox">
      <input class="input" type="text" v-model="comment" placeholder="请输入评论内容"/>
      <div class="submitBtn" @click="commentSubmit">提交</div>
    </div>
    <div class="plmain" v-if="commentList.length > 0">
      <div
        class="plrow"
        v-for="(item, index) in commentList"
        :key="index"
      >
        <div class="pltop">
          <div class="tx">
            <img class="plicon" style="" src="@/assets/images/user.png" />
          </div>
          <div class="plname">{{ item.CreateUser }}</div>
          <div class="pltime">{{ item.Time | dateFilter(item.Time) }}</div>
        </div>
        <div class="pltext">
          {{ item.Content }}
        </div>

        <!-- <div class="pldz">
          <img
            class="plicon"
            style=""
            v-if="item.LikeState == false"
            src="@/assets/images/like.png"
            @click="pldz(item.Id)"
          />
          <img
            class="plicon"
            style=""
            v-else
            src="@/assets/images/like_active.png"
            @click="plqxdz(item.Id)"
          />
          <span>{{ item.LikeCount }}</span>
        </div> -->
      </div>
    </div>
    <div class="notData" v-else>
      <img src="@/assets/images/none_comm@2x.png" />
      <p>暂无评论</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      UserInfo: null,
      detail: {},
      comment: '',
      commentList: []
    }
  },
  computed: {
    id() {
      return this.$route.query.id;
    }
  },
  created() {
    this.UserInfo = sessionStorage.getItem("UserInfo") ? JSON.parse(sessionStorage.getItem("UserInfo")) : null;
    this.getDetail();
    this.getcomment();
  },
  methods: {
    getDetail() {
      this.$axios.get('/Api/Api/Web/Article/GetArt?id='+ this.id).then(res => {
        this.detail = res.Data;
        this.$refs.video.load();
      })
    },
    getcomment() {
      this.$axios.get('/Api/Api/Web/Article/GetComList?id='+ this.id).then(res => {
        this.commentList = res.Data;
      })
    },
    likes() {
      if(this.detail.IsLike) {
        this.$axios.post('/Api/Api/Web/Article/DelHandle', {
          CustomerId: this.UserInfo.Id,
          ArticleId: this.id,
          Type: 3
        }).then(res => {
          if (res.RetCode == '10000') {
            this.detail.IsLike = false;
          }
        })
      } else {
        this.$axios.post('/Api/Api/Web/Article/Handle', {
          CustomerId: this.UserInfo.Id,
          ArticleId: this.id,
          Type: 3
        }).then(res => {
          if (res.RetCode == '10000') {
            this.detail.IsLike = true;
          }
        })
      }
    },
    commentSubmit() {
      if(this.comment) {
        this.$axios.post('/Api/Api/Web/Article/Handle', {
          CustomerId: this.UserInfo.Id,
          ArticleId: this.id,
          Type: 4,
          Content: this.comment
        }).then(res => {
          if (res.RetCode == '10000') { 
            this.comment = '';
            this.$toast.success('评论成功');
            this.getcomment();
          } else {
            this.$toast.fail('评论失败');
          }
        })
      } else {
        this.$toast.fail('评论不能为空');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 15px;
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  .video {
    width: 100%;
    height: 194px;
    margin-top: 15px;
    background-color: #000000;
  }
  .flexBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    .likeBtn {
      display: flex;
      align-items: center;
      font-size: 14px;
      img {
        display: block;
        width: 18px;
        margin-right: 4px;
      }
    }
    .activeLikeBtn {
      color: #EA1600;
    }
  }
  .h2 {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
  }
  .inputBox {
    display: flex;
    margin-top: 15px;
    .input {
      flex: 1;
      height: 35px;
      border-radius: 35px;
      background-color: #F7F7F7;
      border: none;
      padding: 0 10px;
      box-sizing: border-box;
      margin-right: 10px;
      font-size: 14px;
    }
    .submitBtn {
      width: 50px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      background-color: #EA1600;
      border-radius: 8px;
      font-size: 14px;
      color: #FFFFFF;
    }
  }
  .notData {
    img {
      display: block;
      width: 50%;
      margin: 30px auto 10px;
    }
    p {
      font-size: 12px;
      color: #CCCCCC;
      text-align: center;
    }
  }
  .plmain {
    margin: 20px 8px 0;
  }

  .plrow {
    position: relative;
    margin-bottom: 1em;
  }

  .tx {
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    overflow: hidden;
    vertical-align: middle;
    img {
      width: 100%;
      display: block;
    }
  }

  .plname {
    display: inline-block;
    font-size: 14px;
    margin-left: 0.5em;
    color: #999999;
  }

  .pltop {
    position: relative;
  }

  .pltime {
    position: absolute;
    right: 0;
    font-size: 12px;
    top: 22%;
    color: #999999;
  }

  .pltext {
    font-size: 14px;
    color: #333333;
    // padding-left: 34px;
    margin-left: 34px;
    padding: 0 0 10px;
    border-bottom: 1px solid #EEEEEE;
  }

  .pldz {
    margin-left: 34px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid #EEEEEE;
    padding-bottom: 12px;
    img {
      width: 14px;
      height: 14px;
    }
    span {
      margin-left: 4px;
      color: #333333;
      font-size: 12px;
    }
  }
}
</style>